import { FunctionComponent, useContext } from "react";

import { SidebarContext } from "@destination/components";
import { MicroFrontends } from "./MicroFrontends";
import { PageNotFoundListener } from "./PageNotFoundListener";

export const Main: FunctionComponent = () => {
  const { isCollapsed } = useContext(SidebarContext);

  return (
    <div
      className={`flex flex-1 h-screen box-border ${isCollapsed ? "pl-[48px]" : "pl-[150px]"
        }`}
    >
      <main
        id="main-content"
        className="flex grow flex-col overflow-auto pb-[53px] pt-16 pl-[70px] pr-[120px] transition-all duration-200 sm:ml-12 sm:pl-6 sm:pr-8"
      >
        <MicroFrontends />
        <PageNotFoundListener />
      </main>
    </div>
  );
};
