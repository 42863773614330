import { useCallback, useEffect, useState } from "react";

import { SilentRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { getClaimValue } from "@utils/auth/token-utils";

const defaultLanguage = "en";

type User = {
  id: string | null;
  name: string | null;
  language: string;
  organizationId: string | null;
};

export const useCurrentUser = (): {
  user: User | null;
  getAccessToken: (scopes: string[], forceRefresh?: boolean) => Promise<string>;
} => {
  const { instance } = useMsal();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const account = instance.getActiveAccount();
    if (account === null) return;

    const claims = account.idTokenClaims;

    const userId = getClaimValue(claims, "sub");
    const userName = account?.name ?? getClaimValue(claims, "name");
    const language = getClaimValue(claims, "language") ?? defaultLanguage;
    const organizationId = getClaimValue(claims, "organizationId");

    setUser({
      id: userId,
      name: userName,
      language: language,
      organizationId: organizationId,
    });
  }, [instance]);

  const getAccessToken = useCallback(
    async (scopes: string[], forceRefresh?: boolean): Promise<string> => {
      const account = instance.getActiveAccount();
      if (!account) throw Error("No active account");

      const request: SilentRequest = {
        scopes: scopes,
        forceRefresh: forceRefresh ?? false,
        account: account,
      };

      let accessToken = "";
      try {
        const result = await instance.acquireTokenSilent(request);
        accessToken = result.accessToken;
      } catch (error) {
        console.error(error);
      }

      if (!accessToken || accessToken === "") await instance.logoutRedirect();

      return accessToken;
    },
    [instance]
  );

  return { user, getAccessToken };
};
