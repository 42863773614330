import { FunctionComponent, PropsWithChildren } from "react";

import { useRouter } from "../hooks/useRouter";

export type Props = {
  href: string;
  children: ({ isActive }: { isActive: boolean }) => JSX.Element;
  exact?: boolean;
  target?: string;
  onClick?: () => void;
  dataTestId?: string;
};

export const MenuItem: FunctionComponent<Props> = ({
  href,
  children,
  exact,
  target,
  onClick,
  dataTestId,
}) => {
  const { isActive, navigateToUrl } = useRouter();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigateToUrl(href);
    }
  };

  const HyperLinkWithTarget = ({ children }: PropsWithChildren) => {
    return (
      <a
        className="cursor-pointer"
        data-testid={dataTestId}
        rel="noopener"
        href={href}
        target={target}
      >
        {children}
      </a>
    );
  };

  const HyperLinkWithCustomClickHandler = ({ children }: PropsWithChildren) => {
    return (
      <a
        className="cursor-pointer"
        data-testid={dataTestId}
        rel="noopener"
        onClick={handleOnClick}
      >
        {children}
      </a>
    );
  };

  const HyperLink = target
    ? HyperLinkWithTarget
    : HyperLinkWithCustomClickHandler;

  return <HyperLink>{children({ isActive: isActive(href, exact) })}</HyperLink>;
};
